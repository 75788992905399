import { useLazyQuery } from '@apollo/react-hooks'
import { ArrowUp } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import PortalLearningPaths from '@classes/LearningPaths'
import PortalPrograms from '@classes/Programs'
import PortalStudentProfile from '@classes/StudentProfile'
import AllLevelsCompletionModal from '@components/AllLevelsCompletionModal'
import DasboardHeroBannerContainer from '@components/DasboardHeroBannerContainer'
import ElectiveRequiredBanner from '@components/ElectiveRequiredBanner'
import EmptyLearningPathV2 from '@components/EmptyLearningPathV2'
import EmptyLearningPathV3 from '@components/EmptyLearningPathV3'
import { SelectElectiveModal } from '@components/Modal/SelectElectiveModal'
import PrepareNextLevelBanner from '@components/PreparingNxtLvlBanner'
import { StoreProps } from '@interfaces/StoreState'
import HeroHeader from '@layouts/Dashboard/HeroBlock/HeroHeader'
import HeroSubHeader from '@layouts/Dashboard/HeroBlock/HeroSubHeader'
import { FlexContent } from '@layouts/Dashboard/HeroBlock/interface'
import ProgramSelector from '@layouts/Dashboard/HeroBlock/ProgramSelector'
import QueryLearningPath from '@layouts/LearningPath/QueryLearningPathv2'
import { GET_FLEX_CONTENT, GET_ON_DEMAND_FLEX_CONTENT } from '@queries/contentStack'
import { FLEX_LEVEL_STATUS, FLEX_VERSION, JPN_FLEX, ON_DEMAND, PROFILE_TYPE } from '@utils/constants'
import { FEATURE_TYPE } from '@utils/constants'
import { getStoredMaterials } from '@utils/helpers/material'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-styled-flexboxgrid'
import CurriculumTestCompletionModal from '../CurriculumTestCompletionModal'
import HeroCards from './HeroCards'
import {
  CardWrapper,
  Header,
  HeaderContainer,
  Hidden,
  HiddenSpacer,
  Left,
  MaintenanceContainer,
  Right,
  RightNoBorder,
  Tab,
  Visible,
  Wrapper,
} from './style'
import OnlinePlacementTest from '@components/OnlinePlacementTest'
import { learningPathSelector, registrationSelector } from '@components/ActiveState/activeStateSlice'
import { activeLearningPathUpdated } from '@components/ActiveState/activeStateSlice'
import { useDispatch } from 'react-redux'
interface QueryPageResults {
  loading: Boolean
  error?: Error
  data: any
}
interface QueryChildren {
  children: (query: QueryPageResults) => JSX.Element | null
  isOnDemand?: boolean
}

const QueryHeroBlockTransl: React.FC<QueryChildren> = ({ children, isOnDemand }) => {
  const { locale, cognitoLocale, data, loading, error } = useSelector(
    ({ intl, session, preloadedData }: StoreProps) => ({
      locale: intl.locale,
      cognitoLocale: session.user?.attributes?.locale,
      data: preloadedData.heroBlockTransl,
      loading: preloadedData.heroBlockTranslLoading,
      error: preloadedData.heroBlockError,
    })
  )

  const [getFlex, { loading: flexLoading, data: flexData, error: flexError }] = useLazyQuery(GET_FLEX_CONTENT(locale))
  const [getOnDemand, { loading: onDemandLoading, data: onDemandData, error: onDemandError }] = useLazyQuery(
    GET_ON_DEMAND_FLEX_CONTENT(locale)
  )
  const matchingLocale = locale === cognitoLocale && data
  useEffect(() => {
    setTimeout(() => {
      if (matchingLocale && !isOnDemand) {
        return
      }
      isOnDemand ? getOnDemand() : getFlex()
    }, 500)
  }, [isOnDemand, matchingLocale])

  return children({
    data: matchingLocale && !isOnDemand ? data : isOnDemand ? onDemandData : flexData,
    loading: Boolean(loading || flexLoading || onDemandLoading),
    error: error || flexError || onDemandError,
  })
}

const ActiveMaterialSetter = ({ activeDropdownLPId, profile }) => {
  /**
   * This is okay, its because:
   * 1. Browser API's (localStorage) are side effects
   * This use effect will set the active dropdown LP ID
   * when you want to manipulate what is going to be the default value of the
   * ProgramSelector component when it is first loaded
   */
  useEffect(() => {
    if (activeDropdownLPId) {
      profile?.storeActiveDropdownLPId(activeDropdownLPId)
    }
  }, [activeDropdownLPId])

  return <></>
}

const HeroBlock = () => {
  const { profile, programs, activeMaterial, portalStudentInfo, userPermissions, isStudent } = useSelector(
    ({ userProfile, dashboard, permissions }: StoreProps) => {
      const portalStudentInfo = userProfile?.info?.StudentProfile?.StudentProfileInfo
        ? new PortalStudentProfile(
            userProfile?.info?.StudentProfile?.StudentProfileInfo,
            dashboard?.activeMaterial ?? ''
          )
        : {
            MaterialDropDown: [],
            getActiveLPIdFromMaterialDropdown: () => '',
            storeActiveDropdownLPId: () => '',
            getCoreLPId: () => '',
            OPT: {} as any,
          }
      const activeMaterial = portalStudentInfo.getCoreLPId(dashboard?.activeMaterial ?? '')
      return {
        userPermissions: permissions.permissions,
        profile: userProfile?.info,
        portalStudentInfo,
        programs: new PortalPrograms(
          userProfile?.info?.StudentProfile?.StudentProfileInfo?.ProgramStudentWrappers ?? []
        ),
        activeMaterial,
        isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
      }
    }
  )

  const learningPath = useSelector(learningPathSelector.selectLearningPath)
  const registrationIsActive = useSelector(registrationSelector.selectIsActive)
  const dispatch = useDispatch()

  const activeDropdownLPId = portalStudentInfo?.getActiveLPIdFromMaterialDropdown()

  /**
   * You Might Not Need an Effect
   * https://react.dev/learn/you-might-not-need-an-effect
   */

  let registration = profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers?.find(
    ({ RegistrationInfo }) => RegistrationInfo?.Materials?.[0]?.LPID === activeMaterial
  )

  /**
   * If registration var is empty,
   * then we might try to match it with RegistrationId
   */
  if (!registration) {
    registration = profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers?.find(
      ({ RegistrationInfo }) => RegistrationInfo?.RegistrationId === activeMaterial
    )
  }

  const programType = registration?.RegistrationInfo.ProgramType || ''
  const isJapFlexOrOnDemand = JPN_FLEX.includes(programType) || ON_DEMAND.includes(programType)
  const isOnDemand = ON_DEMAND.includes(programType)

  const showFlexHero =
    programs.isProgramFlexV2(activeMaterial) ||
    isOnDemand ||
    profile.FeatureType.includes(FEATURE_TYPE.JAPAN_FLEX) ||
    profile.FeatureType.includes(FEATURE_TYPE.ON_DEMAND) ||
    portalStudentInfo?.OPT?.IsRequired

  const defaultSelected = portalStudentInfo?.MaterialDropDown?.[0]?.value || ''
  const userSelected = getStoredMaterials(profile.SFId) || {}
  const selectedMaterialId = activeMaterial || userSelected || defaultSelected
  const AYCLFlag = userPermissions['flag::AYCL-feature']?.show

  /**
   * This is okay since setHeadOpen is only going to trigger
   * on click.
   */
  const [headerOpen, setHeaderOpen] = React.useState(false)

  if (
    profile.Type !== PROFILE_TYPE.STUDENT ||
    profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers.every(
      ({ RegistrationInfo }) => RegistrationInfo?.Status === 'Cancelled'
    )
  ) {
    return null
  }

  // if (showFlexHero) {
  return (
    <QueryHeroBlockTransl isOnDemand={isOnDemand}>
      {(query) => {
        const { data, loading: translLoading, error } = query

        if (error) {
          return null
        }

        const content: FlexContent = data?.contentstack?.data
          ? JSON.parse(data.contentstack.data)?.[isOnDemand ? 'on_demand_flex_hero' : 'flex_hero']
          : {}
        return (
          <>
            <Wrapper>
              <QueryLearningPath level="core-elective">
                {({
                  loading,
                  error: lpError,
                  LearningPaths,
                }: {
                  loading: boolean
                  error: Error
                  LearningPaths: PortalLearningPaths
                }) => {
                  if (showFlexHero) {
                    const path = LearningPaths.getLearningPathById(selectedMaterialId)
                    const FlexPaths =
                      path?.IsCTMaterial || path?.IsElective
                        ? LearningPaths.getLearningPathById(path?.MaterialCore?.LPID ?? '')
                        : path
                    const electiveRequired = LearningPaths.NeedsElectives
                    const newStudent = !LearningPaths.IsReturningStudent

                    if (path && !learningPath) {
                      dispatch(activeLearningPathUpdated(path))
                    }

                    const showNextSession = (reg, lpLoading, error: Error) => {
                      const isPartOfMultiLevel =
                        AYCLFlag &&
                        [FLEX_VERSION.TWO, FLEX_VERSION.MULTI].indexOf(reg?.RegistrationInfo?.FlexVersion) >= 0 &&
                        reg?.RegistrationInfo?.FlexLevelStatus === FLEX_LEVEL_STATUS.LEVEL_STARTED

                      const showNewEmptyLP =
                        AYCLFlag &&
                        [FLEX_VERSION.SINGLE, FLEX_VERSION.TWO, FLEX_VERSION.MULTI].indexOf(
                          reg?.RegistrationInfo?.FlexVersion
                        ) >= 0

                      const atleast1Active = profile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers.some(
                        ({ RegistrationInfo }) => RegistrationInfo?.Status === 'Active'
                      )

                      /**
                       * Standard checking for show banners
                       */
                      let showPreparationBanners = false

                      if (!FlexPaths && !lpLoading && (isPartOfMultiLevel || showNewEmptyLP || error)) {
                        showPreparationBanners = true
                      } else if (
                        /**
                         * If there is atleast 1 active then we should not show the banner
                         */
                        !FlexPaths &&
                        !lpLoading &&
                        atleast1Active
                      ) {
                        showPreparationBanners = true
                      }

                      const displayPreparationBanner = () => {
                        /**
                         * Lets handle error here
                         * There are 2 types of error UI and we are going
                         * to show it based on what we received from the server.
                         *
                         * if error message contains "graphql" text then we show EmptyLearningPathV3
                         * otherwise show EmptyLEarningPathV2
                         */
                        if (error) {
                          if (error.message.toLowerCase().includes('user not found')) {
                            return (
                              <MaintenanceContainer>
                                <PrepareNextLevelBanner />
                              </MaintenanceContainer>
                            )
                          }

                          if (error.message.toLowerCase().includes('graphql')) {
                            return (
                              <MaintenanceContainer>
                                <EmptyLearningPathV3 description={error.message} />
                              </MaintenanceContainer>
                            )
                          }
                          return (
                            <MaintenanceContainer>
                              <EmptyLearningPathV2 />
                            </MaintenanceContainer>
                          )
                        }
                        /**
                         * There are two types of level
                         * 1. Multi level
                         *  - Berlitz Flex English 1
                         *  - Berlitz Flex English 2
                         *  - ....
                         * 2. Single level
                         */

                        if (isPartOfMultiLevel || atleast1Active) {
                          return (
                            <MaintenanceContainer>
                              <PrepareNextLevelBanner />
                            </MaintenanceContainer>
                          )
                        } else if (showNewEmptyLP) {
                          return (
                            <MaintenanceContainer>
                              <EmptyLearningPathV2 />
                            </MaintenanceContainer>
                          )
                        }
                      }

                      if (electiveRequired && FlexPaths?.Id === electiveRequired.Id) {
                        return (
                          <>
                            <MaintenanceContainer>
                              <ElectiveRequiredBanner Path={electiveRequired} />
                            </MaintenanceContainer>
                            <SelectElectiveModal
                              MainMaterialId={electiveRequired?.Material?.MaterialId || ''}
                              StudentProfileId={profile.SFId}
                              Path={electiveRequired}
                              autoOpen={true}
                            />
                          </>
                        )
                      }

                      if (isJapFlexOrOnDemand) {
                        if (portalStudentInfo?.OPT?.IsRequired) {
                          if (!registrationIsActive) {
                            return (
                              <MaintenanceContainer>
                                <EmptyLearningPathV3 />
                              </MaintenanceContainer>
                            )
                          }
                          return <OnlinePlacementTest registrationId={activeMaterial} />
                        }

                        if (showPreparationBanners) {
                          return displayPreparationBanner()
                        }

                        return (
                          <RightNoBorder>
                            {FlexPaths && FlexPaths.IsRecentlyCompletedAllYouCanLearn && (
                              <AllLevelsCompletionModal learningPath={FlexPaths} />
                            )}
                            <div>
                              <Tab>
                                <FormattedMessage id="Next activities" />
                              </Tab>
                              <CardWrapper>
                                <HeroCards loading={loading} FlexPaths={FlexPaths} error={error} hasNoLCS={true} />
                              </CardWrapper>
                            </div>
                          </RightNoBorder>
                        )
                      }

                      /**
                       * OPT Logic
                       */
                      if (portalStudentInfo?.OPT?.IsRequired) {
                        if (!registrationIsActive) {
                          return (
                            <MaintenanceContainer>
                              <EmptyLearningPathV3 />
                            </MaintenanceContainer>
                          )
                        }
                        return <OnlinePlacementTest registrationId={activeMaterial} />
                      }

                      /**
                       * This logic will only execute if there is no flex plath
                       */
                      if (showPreparationBanners) {
                        return displayPreparationBanner()
                      }

                      return (
                        <Right>
                          {FlexPaths && FlexPaths.IsRecentlyCompletedAllYouCanLearn && (
                            <AllLevelsCompletionModal learningPath={FlexPaths} />
                          )}
                          <div>
                            <Tab>
                              <FormattedMessage id="Next activities" />
                            </Tab>
                            <CardWrapper>
                              <HeroCards loading={loading} FlexPaths={FlexPaths} error={error} />
                            </CardWrapper>
                          </div>
                          <div>
                            <Tab>
                              <FormattedMessage id="Next session" />
                            </Tab>
                            <CardWrapper>
                              <HeroCards loading={loading} FlexPaths={FlexPaths} error={error} coaching />
                            </CardWrapper>
                          </div>
                        </Right>
                      )
                    }

                    return (
                      <>
                        <ActiveMaterialSetter activeDropdownLPId={activeDropdownLPId} profile={portalStudentInfo} />
                        <Visible view="md">
                          <Wrapper>
                            <Left>
                              <HeaderContainer>
                                <Header onClick={() => setHeaderOpen(!headerOpen)}>
                                  <HeroHeader content={content} loading={loading || !!translLoading} />
                                  <ArrowUp color="blue100" size="md" className={headerOpen ? '' : 'icon-rotate'} />
                                </Header>
                                <Row>
                                  <Col xs={12}>
                                    <HeroSubHeader
                                      content={content}
                                      loading={loading || !!translLoading}
                                      hide={!headerOpen}
                                    />
                                  </Col>
                                  <Col xs={12}>
                                    <ProgramSelector
                                      defaultProgram={LearningPaths?.RecentLearningPath || activeMaterial}
                                      hide={!headerOpen}
                                    />
                                    {/* assuming that headerOpen is only manipualted on this view */}
                                    {FlexPaths && headerOpen && <DasboardHeroBannerContainer path={FlexPaths} />}
                                  </Col>
                                </Row>
                              </HeaderContainer>
                            </Left>
                          </Wrapper>
                        </Visible>
                        <HiddenSpacer />
                        <Row>
                          <Col xs={12} lg={4}>
                            <Hidden view="md">
                              <Left>
                                <HeroHeader
                                  content={content}
                                  loading={loading || !!translLoading}
                                  newStudent={newStudent}
                                />
                                <HeroSubHeader
                                  content={content}
                                  loading={loading || !!translLoading}
                                  newStudent={newStudent}
                                />
                                <ProgramSelector
                                  defaultProgram={LearningPaths?.RecentLearningPath || activeMaterial!}
                                />
                                {/* assuming that headerOpen can be ignored in this view */}
                                {FlexPaths && !headerOpen && <DasboardHeroBannerContainer path={FlexPaths} />}
                              </Left>
                            </Hidden>
                          </Col>
                          <Col xs={12} lg={8}>
                            {showNextSession(registration, loading, lpError)}
                            <Spacer size="lg" />
                          </Col>
                        </Row>
                      </>
                    )
                  } else {
                    if (programs.programs.length) {
                      return (
                        <>
                          {programs.programs.length > 1 && <ProgramSelector bgOn defaultProgram={activeMaterial} />}
                          <CurriculumTestCompletionModal />
                          <ProgramSelector defaultProgram={LearningPaths?.RecentLearningPath} hide={true} />
                        </>
                      )
                    }
                    return null
                  }
                }}
              </QueryLearningPath>
            </Wrapper>
          </>
        )
      }}
    </QueryHeroBlockTransl>
  )
  //}
}

export default HeroBlock
